import { Button, Col, Row } from "reactstrap";
import { useContext } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./ConfirmCharity.module.css";
import { isMobile } from "react-device-detect";

function ConfirmCharity() {
  const navigate = useNavigate();
  const { selectedCharity, username, setSelectedCharity } =
    useContext(UtilContext);
  console.log(username, "user");
  if (!username) {
    localStorage.removeItem("user-ai");
    setTimeout(() => {
      navigate("/home");
    }, 100);
    return;
  }
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col className="relative">
        <div
          style={{
            width: isMobile ? "auto" : 460,
            marginTop: isMobile ? 120 : 20,
          }}
          className="container"
        >
          <div onClick={() => navigate("/")} className="back">
            <FaAngleLeft />
          </div>
          <h3>Confirm Your Charity</h3>
          <p>Found you! Is this your charity?</p>
          <div className={`rounded-border mb-3 mt-4 ${classes.name}`}>
            <b>{selectedCharity?.name}</b>
            <div className="mt-3" style={{ fontSize: 13 }}>
              {selectedCharity?.mission}
            </div>
          </div>
          <Button
            outline
            className="w-100 mt-4"
            onClick={() => {
              localStorage.setItem(
                "user-ai",
                JSON.stringify({
                  name: username,
                  charity: "",
                })
              );
              navigate("/");
            }}
          >
            {" "}
            Not My Charity
          </Button>
          <Button
            style={{ backgroundColor: "black" }}
            className="w-100 mt-3"
            onClick={() => {
              navigate("/create-campaign");
              console.log(selectedCharity, "sc");
              localStorage.setItem(
                "user-ai",
                JSON.stringify({
                  name: username,
                  charity: selectedCharity,
                })
              );
            }}
          >
            {" "}
            Next
          </Button>
        </div>
      </Col>
      <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
        <img src={"confirm.png"} alt="" className={classes.img} />
      </Col>
    </Row>
  );
}

export default ConfirmCharity;
