import { Col, Container, Row, Spinner } from "reactstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Calender from "../../assests/CalandarBlack.png";
import { useContext, useEffect, useState } from "react";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./Suggestions.module.css";
import useAxiosWrapper from "../../api/axiosWrapper";

export default function Suggestions() {
  const navigate = useNavigate();
  const [campaignEvents, setCampaignEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosWrapper();

  const { setSelectedSuggestion, selectedTheme, selectedCharity, username } =
    useContext(UtilContext);

  const fetchData = async () => {
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/events";
    const body = {
      theme: selectedTheme,
      charity_id: selectedCharity?.id,
    };
    try {
      setLoading(true);
      const response = await axiosInstance.post(url, body);
      console.log(response, "suggestion response");
      setCampaignEvents(response?.data?.data?.event?.event);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!username) {
      navigate("/");
      return;
    }
    if (!selectedTheme || !selectedCharity) {
      navigate("/home");
      return;
    }
    fetchData();
  }, [selectedTheme, selectedCharity?.id]);

  return (
    <Container>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="">
          <div onClick={() => navigate(-1)} className="back">
            <FaAngleLeft />
          </div>
        </Col>
        <Row className="justify-content-center">
          <Col md={4}>
            <div style={{ marginTop: 120, fontWeight: 600 }}>
              Great! Let’s pick a {selectedTheme?.split("_").join(" ")}.
              <br /> Here are a few suggestions.
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-center gap-4 mt-5"
          style={{ width: 1000 }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              {" "}
              {campaignEvents?.map((event, index) => {
                return (
                  <Col
                    onClick={() => {
                      setSelectedSuggestion(event);
                      navigate("/campaign-suggestions");
                    }}
                    md={4}
                    className={classes.card}
                  >
                    <img
                      src={Calender}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                    <div style={{ marginLeft: 12 }}>
                      {index + 1 + ". " + event}
                    </div>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Row>
    </Container>
  );
}
